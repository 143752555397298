import { AbilityBuilder, createMongoAbility } from '@casl/ability';

export const defineAbilityFor = (user, projectPermissions, typeId, projectType) => {
    const { can, cannot, build } = new AbilityBuilder(createMongoAbility);

    if (user.role === 'Admin') {
        if (!projectPermissions || projectPermissions.length === 0) { // ESCO
            if (typeId === 2 && !projectType.hisOwn) {
                // Admin can view everything
                can('view', 'all'); 
            } else if (typeId === 3 && (!projectPermissions || projectPermissions.length === 0)) {
                cannot('view', 'all');
                cannot('edit', 'all');
            } else {
                // If typeId is ESCO and this is his own project, grant full manage access
                can('manage', 'all');
            }
        } else {
            // Admin with specific permissions
            projectPermissions.forEach(permission => {
                const [entity, action] = permission.name.split('.');
                if (action === 'Edit') {
                    can('edit', entity); // Grant edit permission for the entity
                } else if (action === 'View') {
                    can('view', entity); // Grant view permission for the entity
                }
            });
        }
    } else if (user.role === 'User') {
        // Ensure that Users with typeId 2 can only view
        if (typeId === 2) {
            can('view', 'all'); // Allow view-only permissions
            cannot('edit', 'all'); // Restrict edit permissions
        } else {
            // Parse projectPermissions for other scenarios
            projectPermissions.forEach(permission => {
                const [entity, action] = permission.name.split('.');
                if (action === 'Edit') {
                    can('edit', entity);
                } else if (action === 'View') {
                    can('view', entity);
                }
            });
        }
    }

    return build(); // Build the ability object
};
