import { FormProvider, useForm } from "react-hook-form";
function withFormProvider(Component,defaultValues) {
  return function WrappedComponent(props) {
    const methods = useForm({ defaultValues });
    return (
      <FormProvider {...methods}>
        <Component {...props} />
      </FormProvider>
    );
  };
}

export default withFormProvider