import React, { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import Title from "../shared/Title";
import RadioButton from "../shared/RadioButton";
import DynamicInputs from "../shared/DynamicInputs";
import NumericInput from "../shared/NumericInput";

function RenewableEnergy({ categories }) {
  const { control, watch, setValue } = useFormContext({
    defaultValues: {
      criteriaId: null,
      subCriteriaId: null,
      eligibility: {
        reductionRate: "",
        exclusions: "",
        totalGenerationCapacity: 0,
        alignedToTheIfcperformance: "",
        projectStandardsCertification: "",
        energyDemand: "",
        carbonIntensity: "",
        improvementActivitiesFossilFuel: "",
        involvedInTheExplorationExtraction: "",
        products: [],
      },
    },
  });

  const selectedCriteriaId = watch("criteriaId");
  const selectedSubCriteriaId = watch("SubCriteriaId");
  const [subCriteria, setSubCriteria] = useState({});

  const renderConditionalQuestions = () => {
    if (
      selectedCriteriaId === 4 &&
      subCriteria?.name?.toLowerCase() === "photovoltaic solar"
    ) {
      return (
        <div className='px-5 py-3 mx-2 bg-white flex flex-col justify-between rounded-bl-lg rounded-br-lg'>
          <Title
            text='Is the Concentrated solar power generation >85% ?'
            type='h1'
            style={{ marginBottom: 12 }}
          />
          <Controller
            name='eligibility.istheConcentratedSolarPowerGenerationGreater85percent'
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <div className='flex'>
                <RadioButton
                  label='Yes'
                  value='Yes'
                  checked={field.value === "Yes"}
                  onChange={() => field.onChange("Yes")}
                />
                <RadioButton
                  label='No'
                  value='No'
                  checked={field.value === "No"}
                  onChange={() => field.onChange("No")}
                />
              </div>
            )}
          />
        </div>
      );
    }

    if (
      selectedCriteriaId === 4 &&
      subCriteria?.name?.toLowerCase() === "hydro-power"
    ) {
      return (
        <div className='px-5 py-3 mx-2 bg-white flex flex-col justify-between gap-3 rounded-bl-lg rounded-br-lg'>
          <Title text='Total Generation Capacity (MWh)' type='h1' />
          <Controller
            name='eligibility.totalGenerationCapacity'
            control={control}
            render={({ field }) => (
              <NumericInput {...field} value={field.value} />
            )}
          />
          <div className='border-b border-[#CDCDCD]' />
          <Title
            text='Is the project aligned to the IFC Performance Standards'
            type='h1'
          />
          <Controller
            name='eligibility.alignedToTheIfcperformance'
            control={control}
            render={({ field }) => (
              <div className='flex'>
                <RadioButton
                  label='Yes'
                  value='Yes'
                  checked={field.value === "Yes"}
                  onChange={() => field.onChange("Yes")}
                />
                <RadioButton
                  label='No'
                  value='No'
                  checked={field.value === "No"}
                  onChange={() => field.onChange("No")}
                />
              </div>
            )}
          />
        </div>
      );
    }

    if (selectedCriteriaId === 6) {
      return (
        <div className='px-5 py-3 mx-2 bg-white flex flex-col justify-between gap-3 rounded-bl-lg rounded-br-lg'>
          <Title
            text='Is located on a system with a grid factor at or below 100g CO2/kWh'
            type='h1'
          />
          <Controller
            name='eligibility.isLocatedOnSystemWithaGridFactoratGreater100gCo2kWh'
            control={control}
            render={({ field }) => (
              <div className='flex'>
                <RadioButton
                  label='Yes'
                  value='Yes'
                  checked={field.value === "Yes"}
                  onChange={() => field.onChange("Yes")}
                />
                <RadioButton
                  label='No'
                  value='No'
                  checked={field.value === "No"}
                  onChange={() => field.onChange("No")}
                />
              </div>
            )}
          />
          <div className='border-b border-[#CDCDCD]' />
          <Title
            text='Is the infrastructure located on a system where 67% of recent generation falls below low carbon threshold'
            type='h1'
          />
          <Controller
            name='eligibility.isAtLeast67PercentOfCapacityLast5YearsGreater100gCo2ekWh'
            control={control}
            render={({ field }) => (
              <div className='flex'>
                <RadioButton
                  label='Yes'
                  value='Yes'
                  checked={field.value === "Yes"}
                  onChange={() => field.onChange("Yes")}
                />
                <RadioButton
                  label='No'
                  value='No'
                  checked={field.value === "No"}
                  onChange={() => field.onChange("No")}
                />
              </div>
            )}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <div className='flex flex-wrap p-5 bg-card mt-5 rounded-lg gap-10 sm:gap-5'>
        {categories?.map((item) => {
          const isDimmed =
            selectedCriteriaId !== null && selectedCriteriaId !== item.id;

          return (
            <div
              key={item.id}
              className={`flex flex-col justify-start w-full sm:w-[48%] md:w-[30%] lg:w-[23%] ${
                isDimmed ? "opacity-50" : ""
              }`}
            >
              <Controller
                name='criteriaId'
                control={control}
                render={({ field }) => (
                  <RadioButton
                    label={item.name}
                    value={`${item.id}`}
                    checked={field.value === item.id}
                    onChange={() => {
                      field.onChange(item.id);
                      setValue("SubCriteriaId", 0);
                      setSubCriteria({});
                    }}
                    name='parentCategory'
                  />
                )}
              />

              <div
                className={`bg-white p-5 pb-0 rounded-tr-lg rounded-tl-lg mx-2 flex flex-col mt-3 justify-center ${
                  !renderConditionalQuestions()
                    ? "rounded-bl-lg rounded-br-lg"
                    : ""
                }`}
              >
                {item.children.map((child, i) => {
                  const isLastChild = i === item.children.length - 1;
                  const hasConditionalQuestions =
                    selectedCriteriaId === item.id &&
                    renderConditionalQuestions();

                  return (
                    <div
                      className={`${
                        !hasConditionalQuestions && isLastChild
                          ? "rounded-bl-lg rounded-br-lg" // Apply rounded corners if last child and no questions
                          : ""
                      } ${
                        isLastChild && !hasConditionalQuestions
                          ? ""
                          : "border-b border-[#CDCDCD]"
                      } ${i === 0 ? "pb-3" : "py-3"}`}
                      key={child.id}
                    >
                      <Controller
                        name='SubCriteriaId'
                        control={control}
                        render={({ field }) => (
                          <RadioButton
                            label={child.name}
                            value={`${child.id}`}
                            checked={field.value === child.id}
                            onChange={() => {
                              field.onChange(child.id);
                              setSubCriteria(child);
                            }}
                            name='childCategory'
                            disabled={selectedCriteriaId !== item.id}
                          />
                        )}
                      />
                    </div>
                  );
                })}

                {selectedCriteriaId === item.id && (
                  <div
                    className={`py-3 ${
                      !renderConditionalQuestions() ? "border-t" : "border-b"
                    } border-[#CDCDCD]`}
                  >
                    <Title
                      text='Are lifecycle GHG emissions > 100g CO₂e/kWh?'
                      type='h1'
                      style={{ marginBottom: 12 }}
                    />
                    <Controller
                      name='eligibility.areLifeCycleGhgemissionsGreater100gCo2ekWh'
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <>
                          <RadioButton
                            label='Yes'
                            value='Yes'
                            checked={field.value === "Yes"}
                            onChange={() => field.onChange("Yes")}
                          />
                          <RadioButton
                            label='No'
                            value='No'
                            checked={field.value === "No"}
                            onChange={() => field.onChange("No")}
                          />
                        </>
                      )}
                    />
                  </div>
                )}
              </div>

              {selectedCriteriaId === item.id && renderConditionalQuestions()}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default RenewableEnergy;
