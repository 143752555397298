import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTimeAgo } from "../utilits/helpers";
import { WiTime3 } from "react-icons/wi";
import { AiOutlineNotification } from "react-icons/ai";
import { SlFlag } from "react-icons/sl";
import { MdOutlinePendingActions } from "react-icons/md";
import { LoadingOutlined } from "@ant-design/icons";
import { useResolveFlagMutation, useGetFlagStatusQuery, useGetUserFlagQuery } from "../redux/features/flags";
import useGetItemIdByName from "../hooks/useGetItemIdByName";
import { message } from "antd";

const NotificationList = () => {
  const { notifications, flags } = useSelector((state) => state.notifications);
  const { data: flagStatus, isLoading: isLoadingFetchStatus, error } = useGetFlagStatusQuery();
  const { data, isLoading: isloadingFetch, refetch } = useGetUserFlagQuery();

  const [resolveFlag, { error: errorResolve }] = useResolveFlagMutation();
  const [resolvingId, setResolvingId] = useState(null); // State for tracking resolving item

  const pendingId = useGetItemIdByName(flagStatus, "pending");
  const resolvedId = useGetItemIdByName(flagStatus, "Resolved");

  const handleRemoveNotification = (id) => {
    // dispatch((id));
  };

  const handleResolveFlag = async (id) => {
    try {
      setResolvingId(id); // Set the id of the flag being resolved
      await resolveFlag({ id, statusId: resolvedId }).unwrap();
      message.success("Flag resolved successfully!");
      refetch(); // Refetch flags after resolving
    } catch (err) {
      message.error("Error resolving flag:", err);
      console.error("Error resolving flag:", err);
    } finally {
      setResolvingId(null); // Clear the resolving id after completion
    }
  };

  const status_map = {
    [pendingId]: "Pending",
    [resolvedId]: "Resolved",
  };

  return (
    <div className='min-h-screen bg-gray-100 p-4'>
      <div className='container mx-auto'>
        <h1 className='text-2xl font-bold text-[#1E4A28] mb-4 flex items-center gap-2'>
          <AiOutlineNotification />
          <p>Notifications</p>
        </h1>

        {notifications?.length === 0 || notifications === null ? (
          <p className='text-gray-500'>You have no notifications.</p>
        ) : (
          <ul className='space-y-4'>
            {notifications.map((notification) => (
              <li
                key={notification.id}
                className='bg-white p-4 shadow rounded-lg border border-gray-200'
              >
                <div className='flex items-center justify-between'>
                  <h2 className='font-semibold text-gray-800'>
                    {notification.message}
                  </h2>
                  <button
                    onClick={() => handleRemoveNotification(notification.id)}
                    className='text-red-500 hover:text-red-700 font-semibold'
                  >
                    Remove
                  </button>
                </div>

                <p className='text-gray-500 text-sm flex items-center gap-2'>
                  <WiTime3 />
                  {getTimeAgo(notification.date)}
                </p>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className='container mx-auto mt-20'>
        <h1 className='text-2xl font-bold text-[#1E4A28] mb-4 flex items-center gap-2'>
          <SlFlag />
          <p> Flags</p>
        </h1>

        {Array.isArray(flags) && flags?.length === 0 ? (
          <p className='text-gray-500'>You have no flags.</p>
        ) : (
          <ul className='space-y-4'>
            {flags?.map((flag) => (
              <li
                key={flag.id}
                className='bg-white p-4 shadow-sm hover:shadow-md rounded-lg border border-gray-200 transition-shadow duration-150'
              >
                <div className='flex items-center justify-between'>
                  <h2 className='font-semibold text-gray-800'>
                    {flag.reasonFor}
                  </h2>
                  <button
                    onClick={() => handleResolveFlag(flag.id)}
                    className='text-red-500 hover:text-red-700 font-semibold'
                  >
                    {resolvingId === flag.id ? <LoadingOutlined /> : "Resolve"}
                  </button>
                </div>

                <p className='text-gray-500 text-sm flex items-center gap-2 mt-2'>
                  <MdOutlinePendingActions />
                  {status_map[flag.statusId]}
                </p>
                <p className='text-gray-500 text-sm flex items-center gap-1 mt-2'>
                  <WiTime3 />
                  {getTimeAgo(flag.date)}
                </p>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default NotificationList;
