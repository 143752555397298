import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authHeader } from "../../utilits/authHeader";
import { setFlags } from "../slices/notifications";

export const flagsApi = createApi({
  reducerPath: "flags",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE,
    prepareHeaders: (headers) => {
      headers.set("Authorization", authHeader());

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUserFlag: builder.query({
      query: () => "Flag/UserType",
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setFlags(data || []));
        } catch (err) {
          console.error("Error fetching user flag:", err);
        }
      },
      providesTags: ["Flags"],
    }),
    getUserFlagById: builder.query({
      query: (id) => ({
        url: `Flag/${id}`,
        method: "GET",
      }),
      providesTags: ["Flags"],
      pollingInterval: 5000, // Poll every 5 minutes (300,000 milliseconds)
    }),
    resolveFlag: builder.mutation({
      query: ({ id, statusId }) => ({
        url: `Flag/ResolveFlag`,
        method: "POST",
        params: { id, statusId },
      }),
      invalidatesTags: ["Flags"],
    }),
    getFlagStatus: builder.query({
      query: () => "Flag/FlagStatus",
    }),
  }),
});

export const {
  useGetUserFlagQuery,
  useGetUserFlagByIdQuery,
  useResolveFlagMutation,
  useGetFlagStatusQuery,
} = flagsApi;
