import React, { useState } from "react";
// import '../styles/components/Layout/header.css';
import { Outlet } from "react-router-dom";
import Header from "../components/layout/Header";
import Sidebar from "../components/expert/components/SideBar";
import { useGetUserFlagQuery } from "../redux/features/flags";
import Loader from "../components/shared/Loader";
export default function Layout() {
  const { data, isLoading, error } = useGetUserFlagQuery();
  if (isLoading) return <Loader />;
  return (
    <>
      <Header />
      <main className='greenMe-container h-full'>{<Outlet />}</main>
    </>
  );
}

export const ExpertLayout = () => {
  const [isToggled, setIsToggled] = useState(false);
  const { data, isLoading, error } = useGetUserFlagQuery();
  const expertTabs = [
    {
      name: "Expert Details",
      icon: "details",
      link: `/profile`,
    },
    {
      name: "Assigned Projects",
      icon: "settings",
      link: `/`,
    },
    {
      name: "Missions / Work",
      icon: "progress",
      link: `/assigned-missions`,
    },
  ];

  return (
    <div className='relative'>
      <Header />
      <Sidebar
        tabs={expertTabs}
        isToggled={isToggled}
        setIsToggled={setIsToggled}
      />

      <main className='greenMe-container h-full'>{<Outlet />}</main>
    </div>
  );
};
