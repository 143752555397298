import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  flags: [],
  notifications: [],
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setFlags: (state, action) => {
      state.flags = action.payload;
    },
    resolveFlag: (state, action) => {
      if (state.flags[action.payload] !== undefined) {
        state.flags[action.payload] = false;
      }
    },
    addNotification: (state, action) => {
      state.notifications.push(action.payload);
    },
    removeNotification: (state, action) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload
      );
    },
  },
});

export const { setFlags, removeNotification,resolveFlag } = notificationsSlice.actions;
export default notificationsSlice.reducer;
