import React, { useState } from "react";
import { Upload, message, Spin } from "antd";
import { useFormContext, useFieldArray } from "react-hook-form";
import Icon from "./Icon";
import Button from "./Button";

import { FaCheckCircle, FaTimesCircle } from "react-icons/fa"; // Import icons from react-icons

const UploadComponent = ({
  label,
  objectPropToChange,
  variant = "default",
  disabled = false,
}) => {
  const { control, watch } = useFormContext();
  const [loading, setLoading] = useState(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: `documentSections`,
  });
  const sectionIndex = fields.findIndex(
    (field) => field.name === objectPropToChange.replaceAll(" ", "-")
  );
  const handleFileUpload = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
    }
    if (info.file.status === "done") {
      setLoading(false);
      const newFile = {
        filePath: info.file.response?.fullPath,
      };

      append({
        name: objectPropToChange.replaceAll(" ", "-"),
        documentFiles: [
          ...(fields[sectionIndex]?.documentFiles || []),
          newFile,
        ],
      });
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      setLoading(false);
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const containerStyles = {
    default:
      "w-full bg-[#E2E2E2] h-[120px] border-dashed border border-[#99BAA0] flex justify-between items-center p-4 rounded mb-4 relative",
    card: "w-full bg-[#BFE0C6] h-[120px]  border-dashed border border-[#99BAA0] flex justify-between items-center p-4 rounded mb-4 relative",
  };

  return (
    <div className='my-1 w-full'>
      <label className='font-bold text-[20px] text-[#1E4A28]'>
        {label || null}
      </label>

      {/* Container for file upload */}
      <div className={containerStyles[variant]}>
        {fields &&
        Array.isArray(fields[sectionIndex]?.documentFiles) &&
        fields[sectionIndex]?.documentFiles.length > 0 ? (
          <label htmlFor={`doc - 1}`} className='w-full text-[#1E4A28]'>
            <p className='text-base font-semibold font-abel w-[30%]'>
              <Icon name={"document"} />
              {label || objectPropToChange}
            </p>
          </label>
        ) : (
          <p className='w-full text-lg font-semibold font-abel'>No Documents</p>
        )}
        <DocumentsModal
          fields={fields}
          sectionIndex={sectionIndex}
          itemLabel={label || objectPropToChange}
          remove={remove}
        />
        <Upload
          name='file'
          showUploadList={false}
          action={`${process.env.REACT_APP_API_BASE}FileUpload/upload`}
          onChange={handleFileUpload}
          accept='image/png, image/jpeg, image/jpg ,application/pdf'
          disabled={disabled}
        >
          {loading ? (
            <div className='text-center absolute right-[50%] -translate-y-[50%] translate-x-[50%]'>
              <Spin />
            </div>
          ) : (
            <button
              disabled={disabled}
              type='button'
              className='flex flex-col items-center gap-2 text-[#1E4A28] hover:text-[#166A4D] absolute right-[50%] -translate-y-[50%] translate-x-[50%]'
            >
              {!disabled && (
                <>
                  <Icon name='upload' />
                  <span>Upload File</span>
                </>
              )}
            </button>
          )}
        </Upload>
      </div>
    </div>
  );
};

export default UploadComponent;

const DocumentsModal = ({ fields, sectionIndex, itemLabel, remove }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen(!isOpen);

  const { control } = useFormContext();
  const { update } = useFieldArray({
    control,
    name: `documentSections`,
  });

  const handleApproval = (sectionIndex, status) => {
    update(sectionIndex, {
      ...fields[sectionIndex],
      isapproved: status,
    });
  };

  return (
    <>
      {/* Button to toggle modal */}
      <Button
        onClick={toggleModal}
        variant='secondary'
        className={"relative w-[40px] h-[40px] md:w-[50px] md:h-[50px]"}
        style={{ maxHeight: "50px" }}
      >
        <Icon name='eye' />
      </Button>

      {/* Modal */}
      {isOpen && (
        <div className='fixed top-0 right-0 left-0 z-50 w-full h-full bg-gray-200 bg-opacity-50 flex justify-center items-center'>
          <div className='relative p-4 w-full max-w-2xl max-h-full'>
            <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
              <div className='flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600'>
                <h3 className='text-lg font-semibold text-gray-900 dark:text-white'>
                  {itemLabel?.toUpperCase()} Documents
                </h3>
                <button
                  onClick={toggleModal}
                  type='button'
                  className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm h-8 w-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white'
                >
                  <svg
                    className='w-3 h-3'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 14 14'
                  >
                    <path
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'
                    />
                  </svg>
                  <span className='sr-only'>Close modal</span>
                </button>
              </div>
              <div className='p-4 md:p-5'>
                <ul className='space-y-4 mb-4'>
                  {fields &&
                  Array.isArray(fields[sectionIndex]?.documentFiles) &&
                  fields[sectionIndex]?.documentFiles.length > 0 ? (
                    fields[sectionIndex]?.documentFiles.map((file, index) => (
                      <li
                        key={index}
                        className='flex items-center justify-between'
                      >
                        <label htmlFor={`doc-${index + 1}`} className='w-full'>
                          <a
                            href={file.filePath}
                            className='inline-flex flex-col gap-1 justify-between w-full p-5 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-500 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:bg-gray-600 dark:hover:bg-gray-500'
                          >
                            <p className='w-full text-lg font-semibold'>
                              {`${itemLabel} ${index + 1}`}
                            </p>
                          </a>
                        </label>
                        <button
                          title='remove'
                          className='ml-3 p-4'
                          type='button'
                          onClick={() => remove(index)}
                        >
                          <svg
                            stroke='currentColor'
                            fill='#fff'
                            strokeWidth='0'
                            viewBox='0 0 24 24'
                            height='2em'
                            width='2em'
                            xmlns='http://www.w3.org/2000/svg'
                            className='hover:fill-red-500 transition-colors duration-150'
                          >
                            <path fill='none' d='M0 0h24v24H0z'></path>
                            <path d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z'></path>
                          </svg>
                        </button>
                      </li>
                    ))
                  ) : (
                    <h3 className='text-base font-semibold text-gray-900 dark:text-white text-center'>
                      No Documents
                    </h3>
                  )}
                </ul>

                {/* Approve/Reject Buttons */}
                {/* <div className='flex items-center justify-end gap-2 mt-4'>
                  <Button
                    size='sm'
                    variant='secondary'
                    // onClick={() => handleApproval(sectionIndex, true)}
                  >
                    Approve Section
                  </Button>
                  <Button
                    size='sm'
                    variant='danger'
                    // onClick={() => handleApproval(sectionIndex, false)}
                  >
                    Reject Section
                  </Button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
