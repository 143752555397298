import React from "react";
import UploadComponent from "../../../shared/NewUpload";
import { useFormContext } from "react-hook-form";

function GreenBuilding() {
  const sections = [
    { title: "Proof of Green Building Certifications" },
    { title: "Energy Performance Report" },
    { title: "Other Documents" },
  ];
  const { control, watch } = useFormContext();

  // Get the document sections from the form
  const documentSections = watch("documentSections") || [];

  return (
    <div className='w-full'>
      {sections.map((section, index) => {
        // Find the related document section by matching the title with the name
        // const relatedSection = documentSections.find(
        //   (doc) =>
        //     doc.name.replace(/-/g, " ").toLowerCase() ===
        //     section.title.toLowerCase()
        // );

        return (
          <UploadComponent
            key={section.title}
            label={section.title}
            objectPropToChange={section.title}
          />
        );
      })}
    </div>
  );
}

export default GreenBuilding;
