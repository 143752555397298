import React from 'react';
import { useFormContext } from 'react-hook-form';
import GreenBuilding from './stepthree/GreenBuilding';
import RenewableEnergy from './stepthree/RenewableEnergy';
import EnergyEfficiency from './stepthree/EnergyEfficiency';
import EmptyList from '../../shared/EmptyList';
import { useGetAllCategoriesWithCrietriaQuery } from '../../../redux/features/project';
import useGetItemIdByName from '../../../hooks/useGetItemIdByName';
import Loader from '../../shared/Loader';
function TechnicalStepThree() {
  const {
    data: categories,
    isLoading,
    error,
    isError,
  } = useGetAllCategoriesWithCrietriaQuery();
  const { watch } = useFormContext();
  const categoryId = watch("categoryId");
  const greenBuildingId = useGetItemIdByName(categories, "Green Building");
  const renewableId = useGetItemIdByName(categories, "Renewable Energy");
  const energyEfficiencyId = useGetItemIdByName(
    categories,
    "Energy Efficiency"
  );
  const componentsMap = {
    [greenBuildingId]: <GreenBuilding />,
    [renewableId]: <RenewableEnergy />,
    [energyEfficiencyId]: <EnergyEfficiency />,
  };
  if (isLoading) return <Loader />;
  if (isError) return <EmptyList message={"Failed to load data"} />;
  // Render component based on categoryId or a fallback
  return (
    componentsMap[categoryId] || <EmptyList message={"No data available"} />
  );
}


export default TechnicalStepThree;
