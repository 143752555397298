import React, { useState } from "react";
import UploadComponent from "../../../shared/NewUpload";
import { useFormContext } from "react-hook-form";

function RenewableEnergy() {
  const sections = [
    {
      title: "Energy Yield Report",
      content: "Click the button below to upload your files.",
    },
    {
      title: "Other Documents",
      content: "Select and upload multiple files at once.",
    },
  ];
  const { control, watch } = useFormContext();

  // Get the document sections from the form
  const documentSections = watch("documentSections") || [];
  return (
    <div className='container w-full'>
      {sections.map((section, index) => {
        // Find the related document section by matching the title with the name
        const relatedSection = documentSections.find(
          (doc) =>
            doc.name.replace(/-/g, " ").toLowerCase() ===
            section.title.toLowerCase()
        );

        return (
          <UploadComponent
            key={section.title}
            label={section.title}
            objectPropToChange={section.title}
          />
        );
      })}
    </div>
  );
}

export default RenewableEnergy;
